import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { RegisterForm } from '../interfaces/register-form.interface';
import { LoginForm } from '../interfaces/login-form.interface';
import { CargarUsuario } from '../interfaces/cargar-usuarios.interface';
import { environment } from '../../environments/environment';
import { User } from '../models/user.model';
import { ToastrService } from 'ngx-toastr';
import { Repository } from '../repository/repository';
import { EmailPwd } from '../constants/forms/recovery-password';


declare const gapi: any;


const base_url_v2 = environment.base_url_v2;
const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends Repository {


static instance: UsuarioService;

public auth2: any;
public usuario: User;


constructor(private http: HttpClient,
            private router: Router,
            private toastr: ToastrService,
            private ngZone: NgZone) {
    super(http);
    this.apiURL += '/users';

    UsuarioService.instance = this;
  }



get uid(): string {
   return this.usuario.uid || '';
}

get role() {
  return this.usuario.role;
}
get headers() {
  return {
    headers: {
     'x-token': this.token
    }
};
}


 alerta() {
        this.toastr.info("Sesión expirada");
        this.logout();
    }



  cargarLocalStorage( token: string, menu: any ) {
    localStorage.setItem( 'token', token );
    localStorage.setItem( 'menu', JSON.stringify(menu) );
  }

  getUsersByPage( desde: number = 0 ) {

  return this.http.get<CargarUsuario>(`${ this.apiURL }/page?desde=${ desde }`, this.resHeaders)
                  .pipe(
                    map( resp => {
                      const usuarios = resp.users.map( user =>

                        new User(
                          user.name,
                          user.email,
                          user.password,
                          user.img,
                          user.google,
                          user.role,
                          user.uid,
                          user.clients,
                          )

                      );
                      return {
                        total: resp.total,
                        usuarios
                      };
                    })
                  );

  }

updatePerfil( data: { email: string, name: string, role: string, signature: string, signature_date: string  } ) {
  data = {
    ...data,
    role: this.usuario.role
  };

  return this.http.put(`${ this.apiURL }/${ this.uid }`, data, this.resHeaders);

}




validarToken(): Observable<boolean> {

  return this.http.get(`${ base_url_v2 }/login/renew`, {
    headers: {
      'x-token': this.token
    }
  }).pipe(
    map( (resp: any) => {

      const {name, email, img = '', google, role, uid, clients, signature, signature_date} = resp.user;
      this.usuario = new User(
        name,
        email,
        '',
        img,
        google,
        role,
        uid,
        clients,
        signature,
        signature_date
      );
      this.cargarLocalStorage(resp.token, resp.menu);
      return true;
    }),
    catchError( error => of(false) )
  );

}


login( formData: LoginForm ) {
return this.http.post(`${ base_url_v2 }/login`, formData)
                  .pipe(
                    tap( (resp: any) => {
                      this.cargarLocalStorage(resp.token, resp.menu);
                    })
                  );

}


googleInit() {
 return new Promise<void>( resolve => {
  gapi.load('auth2', () => {
    this.auth2 = gapi.auth2.init({
      client_id: '5674874745-deruq04ht554cr663eh014cm5b9q0pr9.apps.googleusercontent.com',
      cookiepolicy: 'single_host_origin'
    });

    resolve(this.auth2);

  });


 });

}


loginGoogle( token ) {
return this.http.post(`${ base_url_v2 }/login/google`, { token })
                  .pipe(
                    tap( (resp: any) => {
                      this.cargarLocalStorage(resp.token, resp.menu);
                    })
                  );
}

logout() {
localStorage.removeItem('token');
localStorage.removeItem('menu');

 this.ngZone.run( () => {
    this.router.navigateByUrl('/login');
  });


}
updatePasword( password: String ) {

  return this.http.put(`${ base_url_v2 }/users/psswd/${this.uid}`, {password}, this.headers);

}
recoverKey( user_email: String ) {

  return this.http.post(`${ base_url }/recoverKey`, {user_email}, this.headers);

}
sendRecoveryKey( data: EmailPwd ) {
  return this.http.post(`${ base_url_v2 }/login/recoveryKey`, data, this.headers);
}
updatePaswordUser( datos: any ) {
  let password = datos.password;
  let uid = datos.uid;
  let headers= {
    'x-token': datos.token
   }

  return this.http.put(`${ base_url_v2 }/users/psswd/${uid}`, {password}, {headers});

}

}

