import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, AfterViewInit {
  @ViewChild('navList') navList!: ElementRef;
  @ViewChild('navContainer') navContainer!: ElementRef;

  isMenuOpen = false;
  isMoreMenuOpen = false;
  showMoreButton = false;

  mainMenuItems = [
    { icon: 'fa fa-users', label: 'Prospects and Clients', route: '/dashboard/business/megamenu2' },
    { icon: 'fa fa-file-image-o', label: 'Agreement List', route: '/dashboard/agreements' },
    { icon: 'fa fa-file-image-o', label: 'Installation Order', route: '/dashboard/install-order' },
    { icon: 'fa fa-file-image-o', label: 'Services Order', route: '/dashboard/service-order' },
    { icon: 'fa fa-bell', label: 'Order Management', route: '/dashboard/manage-order' },
    { icon: 'fa fa-truck', label: 'Oil Recovery List', route: '/dashboard/uco' },
    { icon: 'fa fa-map-marker', label: 'Reports', route: '/dashboard/customer/reports' }
  ];

  visibleMenuItems = [...this.mainMenuItems];
  hiddenMenuItems: any[] = [];

  constructor(private router: Router) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.checkNavbarOverflow();
    // Asegurar que los elementos estén renderizados
    setTimeout(() => this.checkNavbarOverflow(), 0);
  }

  @HostListener('window:resize')
  onResize() {
    this.checkNavbarOverflow();
  }

  checkNavbarOverflow() {
    if (!this.navList || !this.navContainer) return;

    const containerWidth = this.navContainer.nativeElement.offsetWidth;
    const buttons = this.navList.nativeElement.querySelectorAll('.menu-btn');
    let totalWidth = 0;
    this.visibleMenuItems = [];
    this.hiddenMenuItems = [];

    // Comenzamos con el botón Home (primer botón)
    totalWidth += buttons[0].offsetWidth + 5; // 5px de margen

    // Revisar cada botón del menú
    for (let i = 0; i < this.mainMenuItems.length; i++) {
      const item = this.mainMenuItems[i];
      // Agregar el ancho del botón + margen
      const buttonWidth = buttons[i + 1]?.offsetWidth || 150; // +1 porque el primer botón es Home

      // Verificar si al agregar este botón se excedería el ancho del contenedor
      // Dejamos 100px de espacio para el botón More
      if (totalWidth + buttonWidth < containerWidth - 100) {
        this.visibleMenuItems.push(item);
        totalWidth += buttonWidth + 5; // 5px de margen
      } else {
        this.hiddenMenuItems.push(item);
      }
    }

    this.showMoreButton = this.hiddenMenuItems.length > 0;
  }

  // ... resto de los métodos ...


  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  toggleMoreMenu(event: Event) {
    event.stopPropagation();
    this.isMoreMenuOpen = !this.isMoreMenuOpen;
  }

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  @HostListener('document:click')
  closeMoreMenu() {
    this.isMoreMenuOpen = false;
  }
}
