import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePadModule } from 'angular2-signaturepad';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { WjChartModule } from '@grapecity/wijmo.angular2.chart';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { WjGridFilterModule } from '@grapecity/wijmo.angular2.grid.filter';
import { WjGridSearchModule } from '@grapecity/wijmo.angular2.grid.search';
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { CurrencyMaskModule } from "ng2-currency-mask";


import { PipesModule } from '../pipes/pipes.module';
import { ClientComponent } from './customers/client/view/client.component';

import { AccountSettingsComponent } from './core/account-settings/account-settings.component';
import { PerfilComponent } from './core/perfil/view/perfil.component';
import { UsuariosComponent } from './core/usuarios/view/usuarios.component';
import { AreasComponent } from './core/areas/areas.component';
import { EmpleadosComponent } from './core/employees/empleados.component';


import { SubareasComponent } from './core/subareas/subareas.component';
import { EmpleadoComponent } from './core/employees/empleado.component';

import { InstallationOrderComponent } from './orders/installation/view/installation-order.component';
import { ManagementOrderComponent } from './orders/management/view/management-order.component';
import { ServiceWorkComponent } from './orders/service-work/view/service-work.component';



import { WidgetsModule } from '../shared/widgets.module';
import { ZonesSelectComponent } from './core/selects/zones-select/zones-select.component';
import { StatesSelectComponent } from './core/selects/states-select/states-select.component';
import { BusinesstypeSelectComponent } from './core/selects/businesstype-select/businesstype-select.component';


import { SelectsComponent } from './core/selects/selects.component';
import { ControlComponent } from './core/control/control.component';
import { BusquedaComponent } from './search/busqueda.component';

import { BiComponent } from './bi/view/bi.component';
import { SurveysComponent } from './customers/survey/surveys/surveys.component';
import { AgreementsComponent } from './customers/agreement/agreements/view/agreements.component';
import { AgreementComponent } from './customers/agreement/agreement/view/agreement.component';
import { PreInstallationSurveyComponent } from './customers/survey/pre-installation-survey/pre-installation-survey.component';
import { OilRecoveryComponent } from './customers/oil-recovery/oil-recovery.component';

import { TankOrderComponent } from './orders/tank/view/tank-order.component';
import { WorkreqSelectComponent } from './core/selects/workreq-select/workreq-select.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { MegaMenuComponent } from './core/mega-menu/mega-menu.component';
import { OilCollectionComponent } from './customers/oil-collection/oil-collection.component';
import { UpperCaseInputDirective } from '../directives/to-uppercase.directive';
import { KiteModule } from '../shared/widgets/kite/kite.module';

import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { ResumeComponent } from './customers/resume/resume.component';
import { ClientsComponent } from './eco/clients/clients.component';
import { CreateClientsComponent } from './eco/create-clients/create-clients.component';
import { OrdersComponent } from './eco/orders/orders.component';
import { TrapsComponent } from './eco/traps/traps.component';
import { JetingComponent } from './eco/jeting/jeting.component';
import { CleaningComponent } from './eco/cleaning/cleaning.component';
import { DetailClientComponent } from './eco/detail-client/detail-client.component';

import { WjNavModule } from '@grapecity/wijmo.angular2.nav';
import { DatePipe } from '@angular/common';
import { CreateOticketComponent } from './eco/create-oticket/create-oticket.component';
import { MapComponent } from './customers/map/map.component';
import { SharedModule } from '../layout/shared/shared.module';
import { ReportsComponent } from './customers/reports/reports.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    ClientComponent,
    AccountSettingsComponent,
    PerfilComponent,
    UsuariosComponent,
    AreasComponent,
    EmpleadosComponent,
    EmpleadoComponent,
    BusquedaComponent,
    SubareasComponent,
    AgreementComponent,
    InstallationOrderComponent,
    ManagementOrderComponent,
    ServiceWorkComponent,
    TankOrderComponent,
    PreInstallationSurveyComponent,
    ZonesSelectComponent,
    StatesSelectComponent,
    BusinesstypeSelectComponent,
    SelectsComponent,
    ControlComponent,
    SurveysComponent,
    AgreementsComponent,
    WorkreqSelectComponent,
    DashboardComponent,
    OilRecoveryComponent,
    MegaMenuComponent,
    OilCollectionComponent,
    UpperCaseInputDirective,
    BiComponent,
    ResumeComponent,
    ClientsComponent,
    CreateClientsComponent,
    OrdersComponent,
    TrapsComponent,
    JetingComponent,
    CleaningComponent,
    DetailClientComponent,
    CreateOticketComponent,
    MapComponent,
    ReportsComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PipesModule,
    NgbPaginationModule,
    WidgetsModule,
    KiteModule,
    SignaturePadModule,
    WjGridModule,
    WjChartModule,
    WjInputModule,
    WjGridSearchModule,
    WjGridFilterModule,
    NgxQRCodeModule,
    CurrencyMaskModule,
    AutocompleteLibModule,
    WjNavModule,
    SharedModule,
    MatTabsModule
  ],
  exports: [],
 providers: [CurrencyPipe,DatePipe, {
  provide: ErrorHandler,
  useValue: Sentry.createErrorHandler({
    showDialog: false,
  }),
},
{
  provide: Sentry.TraceService,
  deps: [Router],
},
{
  provide: APP_INITIALIZER,
  useFactory: () => () => {},
  deps: [Sentry.TraceService],
  multi: true,
},],
})
export class PagesModule { }
