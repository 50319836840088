<div id="main-wrapper">

    <app-header></app-header>


    <!-- <app-sidebar></app-sidebar> -->

    <main>

        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <section class="container" style="padding-top: 84px; min-height: 100vh; ">

            <!-- =====================Breadcrumbs========================== -->
            <!-- <app-breadcrumbs></app-breadcrumbs> -->
            <!-- ===================== Mi Sistema de Rutas========================================= -->
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->


            <router-outlet></router-outlet>


            <!-- ============================================================== -->
            <!-- End PAge Content -->
            <!-- ============================================================== -->

        </section>

        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <!-- <footer class="footer">
            © 2020
        </footer> -->
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->

    </main>


</div>



<!-- *** Modal Component -->
<app-modal-imagen></app-modal-imagen>
