import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalImagenComponent } from './widgets/modal-imagen/modal-imagen.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AdvancedTableComponent } from './widgets/advanced-table/advanced-table.component';
import { NgbSortableHeaderDirective } from './widgets/advanced-table/sortable.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../../app/pipes/pipes.module';
import { BaseInputComponent } from './forms/base-input.component';
import { ImageUploaderComponent } from './forms/image-uploader/image-uploader.component';
import { ErrorsComponent } from './forms/errors/errors.component';
import { BaseComponent } from './base/base.component';
import { ImageTableViewerComponent } from './widgets/image-table-viewer/image-table-viewer.component';
import { FormVisitPlanComponent } from './widgets/forms/form-visit-plan/form-visit-plan.component';
import { FormClientComponent } from './widgets/forms/form-client/form-client.component';
import { FormNewClientComponent } from './widgets/forms/form-new-client/form-new-client.component';
import { SignaturePadComponent } from './widgets/signature-pad/signature-pad.component';
import { TableTwoColComponent } from './widgets/tables/table-two-col/table-two-col.component';
import { TableThreeColComponent } from './widgets/tables/table-three-col/table-three-col.component';
import { TableKiteComponent } from './widgets/tables/table-kite/table-kite.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { KiteModule } from './widgets/kite/kite.module';
import { InputComponent } from './forms/input/input.component';
import { CreateQrCodesModalComponent } from './widgets/create-qr-codes-modal/create-qr-codes-modal.component';
import { FeedbackModalComponent } from './widgets/feedback-modal/feedback-modal.component';
import { PersonalizedDialogComponent } from './widgets/personalized-dialog/personalized-dialog.component';
import { WjGridModule } from '@grapecity/wijmo.angular2.grid';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { MatDialogModule } from '@angular/material/dialog';
import { CreateNewClientModalComponent } from './widgets/customers/create-business-prospect-modal/create-new-client-modal.component';
import { AddressAutocompleteComponent } from './widgets/address-autocomplete/address-autocomplete.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CreateNewBusinessNameModalComponent } from './widgets/create-new-business-name-modal/create-new-business-name-modal.component';
import { UpdateBusinessInformationModalComponent } from './widgets/customers/update-business-information-modal/update-business-information-modal.component';
import { ButtonComponent } from './widgets/button/button.component';
import { ClientsReportComponent } from './widgets/customers/reports/clients-report/clients-report.component';
import { ReconciliationComponent } from './widgets/customers/resume/reconciliation/reconciliation.component';
import { CreateNewReconciliationModalComponent } from './widgets/customers/resume/create-new-reconciliation-modal/create-new-reconciliation-modal.component';
import { AddPaymentInformationModalComponent } from './widgets/customers/resume/add-payment-information-modal/add-payment-information-modal.component';
import { PaymentInformationModalComponent } from './widgets/customers/resume/payment-information-modal/payment-information-modal.component';
import { TextareaComponent } from './widgets/textarea/textarea.component';
import { ReadOnlyFieldComponent } from './widgets/read-only-field/read-only-field.component';
import { ReconciliationInformationComponent } from './widgets/customers/resume/reconciliation-information/reconciliation-information.component';
import { InputCustomComponent } from './widgets/input/input.component';
import { SelectComponent } from './widgets/select/select.component';
import { CurrencyInputComponent } from './widgets/currency-input/currency-input.component';


@NgModule({
  declarations: [
    BaseComponent,
    ModalImagenComponent,
    AdvancedTableComponent,
    NgbSortableHeaderDirective,
    SignaturePadComponent,
    FormVisitPlanComponent,
    FormClientComponent,
    TableTwoColComponent,
    TableThreeColComponent,
    TableKiteComponent,
    BaseInputComponent,
    ImageUploaderComponent,
    ErrorsComponent,
    ImageTableViewerComponent,
    FormNewClientComponent,
    InputComponent,
    CreateQrCodesModalComponent,
    FeedbackModalComponent,
    PersonalizedDialogComponent,
    CreateNewClientModalComponent,
    AddressAutocompleteComponent,
    CreateNewBusinessNameModalComponent,
    UpdateBusinessInformationModalComponent,
    ButtonComponent,
    ClientsReportComponent,
    ReconciliationComponent,
    CreateNewReconciliationModalComponent,
    AddPaymentInformationModalComponent,
    PaymentInformationModalComponent,
    TextareaComponent,
    ReadOnlyFieldComponent,
    ReconciliationInformationComponent,
    InputCustomComponent,
    SelectComponent,
    CurrencyInputComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SignaturePadModule,
    PdfViewerModule,
    KiteModule,
    PipesModule,
    WjGridModule,
    WjInputModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatFormFieldModule
  ],
  exports: [
    BaseComponent,
    ModalImagenComponent,
    AdvancedTableComponent,
    SignaturePadComponent,
    FormVisitPlanComponent,
    FormClientComponent,
    TableTwoColComponent,
    TableThreeColComponent,
    TableKiteComponent,
    BaseInputComponent,
    ImageUploaderComponent,
    ErrorsComponent,
    ImageTableViewerComponent,
    FormNewClientComponent,
    InputComponent,
    PersonalizedDialogComponent,
    AddressAutocompleteComponent,
    ButtonComponent,
    ClientsReportComponent,
    ReconciliationComponent
  ]
})
export class WidgetsModule { }
